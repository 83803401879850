import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import fallBackLogo from '../../images/logo.png';
import LoginModal from '../LoginModal';
import { IoLogOutSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';
import HamburgerMenu from '../HamburgerMenu';
import { Dropdown } from 'react-bootstrap';

import { SlUser, SlUserFemale } from 'react-icons/sl';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaArrowRight } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { toggleScrollBarFormRootHTMLTag } from '../../utils/CommonFunctions';
import api from '../../utils/api';

function Nav() {
  const Logo =
    JSON.parse(localStorage.getItem('org'))?.horizontalLogo || fallBackLogo;
  let date = new Date();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { orgName } = useParams();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [onGoingApplication, setOnGoingApplication] = useState(false);
  const orgId = JSON.parse(localStorage.getItem('org'))?.id;
  const userDetails = JSON.parse(localStorage.getItem('user') || '{}');
  const userGender = userDetails?.gender || 'MALE';
  const dropdownRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const openLoginModal = JSON.parse(localStorage.getItem('openLoginModal'));
    if (openLoginModal) {
      localStorage.removeItem('openLoginModal');
      setIsLoginModalOpen(true);
    }
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (dropdownRef.current) {
      document.addEventListener('mousedown', checkIfClickedOutside);
    }

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [dropdownRef, isDropdownOpen]);

  const fetchAllApplicationUserHaveAppliedFor = async () => {
    try {
      const url = `/v1/organization/${orgId}/applied-applications`;
      const response = await api.get(url);
      const data = response.data;

      if (data.statusCode === 200) {
        let jobsObj = {};
        data?.data?.applications.forEach((application) => {
          jobsObj[application.job] = { ...application };
        });
        dispatch({ type: 'SET_APPLIED_JOBS_OBJ', payload: jobsObj });
        const ongoing = Object.values(data?.data?.applications || {}).filter(
          ({ status }) =>
            ![
              'REJECTED',
              'ACCEPTED',
              'HIRED',
              'PRE_ASSESSMENT_PASSED'
            ].includes(status)
        ).length;
        setOnGoingApplication(() => ongoing);
      } else {
        console.error('Failed to fetch applications :', data.message);
      }
    } catch (error) {
      console.error('Error fetching applications:', error);
    }
  };

  // const handleContinueApplication = () => {
  //   const { status, job, _id: id } = onGoingApplication || {};
  //   if (status === "APPLICATION_SUBMITTED" || status === "PROFILE_ANALYZED") {
  //     navigate(`/${orgName}/analyze-candidate/details/${job}/${id}`);
  //   } else if (
  //     status === "AI_ANSWERS_SUBMITTED" ||
  //     status === "AI_ANSWERS_ACCEPTED"
  //   ) {
  //     navigate(`/${orgName}/analyze-candidate/questions/${job}/${id}`);
  //   }
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (window.location.pathname.includes(`${orgName}/analyze-candidate`)) {
        return;
      }
      const navBar = document.getElementById('nav-bar');
      if (navBar) {
        if (window.scrollY >= 20) {
          navBar.classList.add('bg-primary-600', 'pb-5');
          navBar.classList.remove('bg-transparent');
        } else {
          navBar.classList.add('bg-transparent');
          navBar.classList.remove('bg-primary-600', 'pb-5');
        }
      }
    };

    fetchAllApplicationUserHaveAppliedFor();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [orgName]);

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    setOnGoingApplication(false);
    const path = location.pathname;
    if (path !== `/${orgName}` && !path.startsWith(`${orgName}/job/`)) {
      navigate(`/${orgName}`);
    }
    const preserveKey = 'org';
    const keys = Object.keys(localStorage);

    keys.forEach((key) => {
      if (key !== preserveKey) {
        localStorage.removeItem(key);
      }
    });
    closeDropdown();
    setIsOpen(false);
    toast.success('Logged out successfully');
  };

  const handleLoginClick = () => {
    toggleScrollBarFormRootHTMLTag(true);
    setIsLoginModalOpen(true);
  };

  const handleChangePasswordClick = () => {
    setIsChangePasswordModalOpen(true);
    toggleScrollBarFormRootHTMLTag(true);
  };

  const handleCreateJobClick = () => {
    setIsJobModalOpen(true);
    toggleScrollBarFormRootHTMLTag(true);
  };

  const handleCloseModal = () => {
    setIsJobModalOpen(false);
    toggleScrollBarFormRootHTMLTag(false);
  };

  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
    toggleScrollBarFormRootHTMLTag(false);
  };
  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
    toggleScrollBarFormRootHTMLTag(false);
  };

  const userData = JSON.parse(localStorage.getItem('user'));
  const userRole = userData?.role || '';

  return (
    <>
      <nav
        id="nav-bar"
        className="fixed left-0 right-0 selection:h-20 z-[2] bg-transparent transition-colors duration-300"
      >
        <div className="container mx-auto w-full px-5 sm:px-0 ">
          <div className="flex items-center justify-between pt-5">
            {location.pathname.includes(`${orgName}/questions/`) ||
            location.pathname.includes(`${orgName}/Disc-Assesment`) ||
            location.pathname.includes(`${orgName}/preassesment/`) ||
            location.pathname.includes(`${orgName}/aptitude/`) ||
            location.pathname.includes('/critical-thinking-assessment') ||
            location.pathname.includes('/motivators-questionnaire') ? (
              <div className="cursor-pointer">
                <img src={Logo} alt="Logo" className="h-12" />
              </div>
            ) : (
              <Link to={`/${orgName}`}>
                <img src={Logo} alt="Logo" className="h-12" />
              </Link>
            )}

            <div className="flex gap-[20px] max-lg:gap-[10px] pl-[2vh]">
              {isLoggedIn ? (
                <>
                  {userRole === 'ADMIN' && (
                    <button
                      onClick={handleCreateJobClick}
                      className="text-white font-medium text-sm px-5 py-2.5"
                    >
                      Create a Job
                    </button>
                  )}
                  {(location.pathname === `/${orgName}` ||
                    location.pathname === `/${orgName}/` ||
                    location.pathname.includes(`${orgName}/job/`)) && (
                    <Link
                      to={`/${orgName}/appliedjob`}
                      className="relative flex text-primary-600 border-2 bg-white px-4 py-2.5 font-medium text-sm rounded items-center text-[0.875rem] leading-[1rem] hover:text-white hover:bg-transparent hover:border-white transition-all duration-300"
                    >
                      Applied Jobs
                      {onGoingApplication > 0 && (
                        <span className="absolute -top-3 -right-2 bg-primary-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs shadow-[0_0_5px_2px_rgba(255,255,255,0.6)]">
                          {onGoingApplication}
                        </span>
                      )}
                    </Link>
                  )}
                  {location.pathname.includes(`${orgName}/questions/`) ||
                  location.pathname.includes(`${orgName}/Disc-Assesment`) ||
                  location.pathname.includes(`${orgName}/preassesment/`) ||
                  location.pathname.includes(`${orgName}/aptitude/`) ||
                  location.pathname.includes('/critical-thinking-assessment') ||
                  location.pathname.includes('/motivators-questionnaire') ? (
                    <></>
                  ) : (
                    <>
                      <div className="relative max-lg:hidden" ref={dropdownRef}>
                        <div
                          className="rounded border h-10 w-10 flex items-center justify-center hover:opacity-90 cursor-pointer"
                          onClick={toggleDropdown}
                        >
                          {userGender === 'FEMALE' ? (
                            <SlUserFemale color="#eee" size={'1.5rem'} />
                          ) : (
                            <SlUser color="#eee" size={'1.5rem'} />
                          )}
                        </div>
                        {isDropdownOpen && (
                          <div className="absolute top-[53px] right-0 mr-2 bg-white rounded-md shadow-md flex flex-col max-w-[15rem] overflow-hidden">
                            <div className="absolute -top-2 right-3 w-4 h-4 bg-white transform rotate-45 border-t border-l border-gray-200"></div>
                            <div className="relative z-10 bg-white rounded-md">
                              <div className="flex items-center px-3 py-4 h-14 text-sm font-bold text-primary-500 rounded-md border-b border-[#d9d9d9]">
                                <AiOutlineMail
                                  className="text-primary-500 mr-2 flex-shrink-0"
                                  size={18}
                                />
                                <span className="truncate flex-grow min-w-0">
                                  {(() => {
                                    const user = JSON.parse(
                                      localStorage.getItem('user')
                                    );
                                    return user ? user.email : '';
                                  })()}
                                </span>
                              </div>{' '}
                              <div
                                className="flex items-center px-3 py-2 h-14 text-sm text-[#888888] rounded-md border-b border-[#d9d9d9] active:bg-[#4885ed1a] active:text-primary-500 cursor-pointer hover:text-[#514e5f] hover:bg-[#e9ecef]"
                                onClick={() => {
                                  handleChangePasswordClick();
                                  closeDropdown();
                                }}
                              >
                                <svg
                                  id="icon-user1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="text-primary-500"
                                  width={18}
                                  height={18}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                  <circle cx={12} cy={7} r={4} />
                                </svg>
                                <span className="ms-2">Change Password</span>
                              </div>
                            </div>
                            <div
                              className="flex items-center px-3 py-2 h-14 text-sm text-[#888888] rounded-md border-b border-[#d9d9d9] active:bg-[#4885ed1a] active:text-primary-500 cursor-pointer hover:text-[#514e5f] hover:bg-[#e9ecef]"
                              onClick={handleLogout}
                            >
                              <svg
                                id="icon-logout"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-[#f72b50]"
                                width={18}
                                height={18}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                <polyline points="16 17 21 12 16 7" />
                                <line x1={21} y1={12} x2={9} y2={12} />
                              </svg>
                              <span className="ms-2">Logout </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className=" z-50 lg:hidden">
                        <button
                          onClick={toggleMenu}
                          className="text-white text-[35px] h-10 w-10 flex items-center text-center justify-center focus:outline-none"
                        >
                          <div>
                            {isOpen ? (
                              <FaArrowRight size={20} />
                            ) : (
                              <GiHamburgerMenu />
                            )}
                          </div>
                          {/* Hamburger Icon */}
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <button
                    onClick={handleLoginClick}
                    className="text-primary-600 border-2 bg-white px-4 py-2.5 font-medium text-sm rounded hover:text-white hover:bg-transparent hover:border-white transition-all duration-300"
                  >
                    Login | Signup
                  </button>
                </>
              )}
            </div>
          </div>
          <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="flex flex-col justify-between hamburger-inner-menu bg-white shadow-lg rounded-md">
              <div className="flex flex-col py-3 ">
                <div
                  className="relative flex items-center px-3 py-4 h-14 text-sm font-bold text-primary-500 rounded-md border-b border-[#d9d9d9]"
                  style={{ overflowWrap: 'hidden' }}
                >
                  <AiOutlineMail
                    className="text-primary-500 mr-2 flex-shrink-0"
                    size={18}
                  />
                  <span className="truncate">
                    {(() => {
                      const user = JSON.parse(localStorage.getItem('user'));
                      return user ? user.email : '';
                    })()}
                    <div className="absolute left-0 bottom-full mb-2 w-auto px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity">
                      {(() => {
                        const user = JSON.parse(localStorage.getItem('user'));
                        return user ? user.email : '';
                      })()}
                    </div>
                  </span>
                </div>

                <div
                  className="flex items-center px-3 py-3 text-base h-16 text-[#888888] border-b border-[#d9d9d9] active:bg-[#4885ed1a] active:text-primary-500 cursor-pointer hover:text-[#514e5f] hover:bg-[#e9ecef]"
                  onClick={() => {
                    handleChangePasswordClick();
                    closeDropdown();
                    setIsOpen(false);
                  }}
                >
                  <svg
                    id="icon-user1"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-primary-500"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                    <circle cx={12} cy={7} r={4} />
                  </svg>
                  <span className="ms-2">Change Password</span>
                </div>
                <div
                  className="flex items-center px-3 py-3 text-base h-16 text-[#888888] border-b border-[#d9d9d9] active:bg-[#4885ed1a] active:text-primary-500 cursor-pointer hover:text-[#514e5f] hover:bg-[#e9ecef]"
                  onClick={handleLogout}
                >
                  <svg
                    id="icon-logout"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-[#f72b50]"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1={21} y1={12} x2={9} y2={12} />
                  </svg>
                  <span className="ms-2">Logout </span>
                </div>
              </div>

              <div className="text-xs self-center justify-self-end pb-4">
                <strong>TalentSwap</strong>
                <p className="text-[#9fa4a6]">
                  © {date.getFullYear()} All Rights Reserved
                </p>
              </div>
            </div>
          </HamburgerMenu>
        </div>
      </nav>

      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={handleCloseLoginModal}
        fetchAllApplications={fetchAllApplicationUserHaveAppliedFor}
      />

      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={handleCloseChangePasswordModal}
      />
    </>
  );
}

export default Nav;
