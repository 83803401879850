import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ApplicationStatusProgress from './ApplicationProgression';
import './styles.css';
import jobSpecificDetails from '../../constants/jobSpecificDetails';
import TestProcesses from './TestProcesses';
import { useHelperBot } from '../HelperBot';
import api from '../../utils/api';

// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const AppliedJobDetail = () => {
  const navigate = useNavigate();
  const { orgName } = useParams();
  const { jobId, appId } = useParams();
  const location = useLocation();
  const [appData, setAppData] = useState(null);
  const [resumeLink, setResumeLink] = useState('');
  const [jobData, setJobData] = useState(
    location.state || { reasonsToShowToCandidate: [] }
  );
  const [isHired, setIsHired] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const appliedJobsObj = useSelector((state) => state.appliedJobsObj);
  const dispatch = useDispatch();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [onGoingApplication, setOnGoingApplication] = useState({});
  const [otherOnGoingApplication, setOtherOnGoingApplication] = useState(false);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const { helperMessage } = useHelperBot();

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  const fetchJobDetails = async () => {
    try {
      const { data } = await api.get(`/v1/organization/${orgId}/job/${jobId}`);
      if (data.statusCode === 200) {
        let reqData = data.data;
        const responsibilities = reqData.responsibilities;
        const responsibilitiesArray = responsibilities
          .split('. ')
          .map((item) => item.trim())
          .filter((item) => item);
        reqData['responsibilities'] = responsibilitiesArray;
        setJobData(reqData);
      } else {
        toast.error(data.message || 'An error occurred');
        throw new Error('API issue');
      }
    } catch (error) {
      console.error('Error fetching Job details:', error);
      navigate(`/${orgName}/404`);
    }
  };

  useEffect(() => {
    if (!location.state) {
      fetchJobDetails();
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsResumeModalOpen(false);
      }
    };

    if (isResumeModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isResumeModalOpen]);

  useEffect(() => {
    fetchAppliedJobDetail();
  }, []);

  useEffect(() => {
    if (appData) {
      handleViewResume();
    }
  }, [appData]);

  useEffect(() => {
    if (isResumeModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isResumeModalOpen]);

  const fetchAppliedJobDetail = async () => {
    try {
      const { data } = await api.get(
        `/v1/organization/${orgId}/job/${jobId}/application/${appId}`
      );

      if (data.statusCode === 200) {
        setAppData(data.data);
        setJobData((prevData) => ({
          ...prevData,
          reasonsToShowToCandidate:
            data.data?.application?.reasonsToShowToCandidate || []
        }));
        setIsHired(data.data.application.status === 'HIRED');
      } else {
        toast.error(data.message || 'An error occurred');
        throw new Error(data.message || 'API issue');
      }
    } catch (error) {
      toast.error(error.message);
      console.error('Fetch error: ', error.message);
      navigate(`/${orgName}/404`);
    }
  };

  const handleViewResume = async () => {
    try {
      const objectKey = appData?.application?.resume;
      const { data } = await api.post('/v1/media/signed-url', { objectKey });

      if (data.data.signedUrl) {
        setResumeLink(data.data.signedUrl);
      } else {
        console.error('Fetch error: ', data.message);
        throw new Error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOptOutClick = async () => {
    try {
      const { data } = await api.post(
        `/v1/organization/${orgId}/job/${jobId}/application/${appId}/withdraw-application`,
        {
          reason:
            "You've withdrawn your application. You cannot reapply but can explore other opportunities."
        }
      );

      if (data.status === 200) {
        toast.success(data.message);
        navigate(`/${orgName}/appliedjob`);
      } else {
        throw new Error(data.message || 'API issue');
      }
    } catch (error) {
      toast.error(error.message);
      console.error('Fetch error: ', error.message);
    }
  };

  const toggleDescriptionVisibility = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return 'N/A';
    }

    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  const status = appData?.application?.status;
  const statusHistory = appData?.application?.statusHistory;
  const isWithdrawn = statusHistory?.some(
    (status) => status.currentStatus === 'CANDIDATE_WITHDRAWN'
  );
  const jobStatus =
    status === 'REJECTED'
      ? 'Rejected'
      : status === 'PRE_ASSESSMENT_PASSED'
        ? 'Accepted'
        : 'In Process';

  const {
    companyName,
    title,
    applicationDate,
    reasonsToShowToCandidate = [],
    jobDescriptionSummary,
    job
  } = jobData;

  useEffect(() => {
    helperMessage(
      'Hey there, You can track the status of your application here, review the details and track your next steps right here.'
    );
  }, []);

  if (!appData) {
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
        <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 font-sans leading-relaxed tracking-wide">
      <div className="bg-[url('images/headerbg.png')] bg-no-repeat bg-center bg-cover h-[304px] relative bg-primary-600 z-[1]"></div>
      {/* <div className="grid grid-cols-12 lg:gap-12">
        <div className="col-span-12 xl:w-full xl:col-span-8"> */}
      <div className="mt-[-144px] mb-[10vh] z-[1] max-w-4xl mx-auto p-6 sm:p-10 relative bg-white shadow-lg rounded-lg  animate-fade-in">
        {/* <!-- Header Section --> */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-4xl font-bold text-gray-800">
              Application Status
            </h1>
            <p className="text-gray-500 mt-2">{title || 'Job Title'}</p>
          </div>
          <a
            href={`/${orgName}/appliedjob`}
            className="bg-primary-500 text-white px-5 py-2.5 rounded-md hover:bg-primary-600 transition duration-300 focus-ring"
          >
            Back
          </a>
        </div>

        {/* <!-- Application Status Section --> */}
        {isHired ? (
          <div className="hover-highlight flex-1 mt-10 p-6 max-sm:p-3 bg-gray-50 rounded-lg flex items-center   transition-all duration-300">
            <div className="w-20 h-20 bg-green-500 text-white rounded-full flex items-center justify-center">
              <svg
                className="w-10 h-10"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>
            <div className=" flex-1 ml-6 max-sm:ml-5">
              <h2 className="text-3xl font-semibold text-gray-800">
                Application Accepted
              </h2>
              <p className="text-gray-600 mt-2">
                Congratulations! You have been hired for the position.
              </p>
            </div>
          </div>
        ) : status === 'REJECTED' && isWithdrawn ? (
          <div className="hover-highlight flex-1 mt-10 p-6 max-sm:p-3 bg-gray-50 rounded-lg flex items-center transition-all duration-300">
            <div className="w-20 h-20 bg-yellow-500 text-white rounded-full flex items-center justify-center">
              <svg
                className="w-10 h-10"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div className="flex-1 ml-6 max-sm:ml-5">
              <h2 className="text-3xl font-semibold text-gray-800">
                Application Withdrawn
              </h2>
              <p className="text-gray-600 mt-2">
                {appData?.application?.withdrawReason ||
                  'You’ve withdrawn your application. You cannot reapply but can explore other opportunities.'}
              </p>
            </div>
          </div>
        ) : status === 'REJECTED' ? (
          <div className="hover-highlight flex-1 mt-10 p-6 max-sm:p-3 bg-gray-50 rounded-lg flex items-center   transition-all duration-300">
            <div className="w-20 h-20 bg-red-500 text-white rounded-full flex items-center justify-center">
              <svg
                className="w-10 h-10"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
            <div className=" flex-1 ml-6 max-sm:ml-5">
              <h2 className="text-3xl font-semibold text-gray-800">
                Application Rejected
              </h2>
              <p className="text-gray-600 mt-2">
                Your application was reviewed but did not meet the required
                criteria.
              </p>
            </div>
          </div>
        ) : status === 'PRE_ASSESSMENT_PASSED' ? (
          <div className="hover-highlight flex-1 mt-10 p-6 max-sm:p-3 bg-gray-50 rounded-lg flex items-center   transition-all duration-300">
            <div className="w-20 h-20 bg-green-500 text-white rounded-full flex items-center justify-center">
              <svg
                className="w-10 h-10"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 12l5 5L19 7"
                ></path>
              </svg>
            </div>
            <div className=" flex-1 ml-6 max-sm:ml-5">
              <h2 className="text-3xl font-semibold text-gray-800">
                Application Accepted
              </h2>
              <p className="text-gray-600 mt-2">
                Congratulations! Your application was accepted.
              </p>
            </div>
          </div>
        ) : (
          <div className="hover-highlight flex-1 mt-8 p-6 max-sm:p-3 bg-gray-50 rounded-lg flex flex-col sm:flex-row items-center sm:justify-between transition-all duration-300">
            <div className="flex items-center mb-4 sm:mb-0">
              <div className="w-16 h-16 sm:w-20 sm:h-20 bg-primary-500 text-white rounded-full flex items-center justify-center">
                <svg
                  className="w-8 h-8 sm:w-10 sm:h-10"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 2h12M6 22h12M8 2v6l4 4-4 4v6M16 2v6l-4 4 4 4v6"
                  ></path>
                </svg>
              </div>
              <div className="flex-1 ml-4 sm:ml-6">
                <h2 className="text-xl sm:text-3xl font-semibold text-gray-800">
                  Application In Progress
                </h2>
                <p className="text-sm sm:text-base text-gray-600 mt-1 sm:mt-2">
                  Your application is currently in progress.
                </p>
              </div>
            </div>
            <button
              className="flex items-center justify-center min-w-full sm:min-w-[122px] gap-1.5 py-2 px-3 sm:py-2.5 sm:px-4 bg-red-500 rounded cursor-pointer hover:bg-red-700 text-white transition-all duration-300"
              onClick={handleOptOutClick}
            >
              Withdraw Application
            </button>
          </div>
        )}

        {/* <!-- Application Details Section --> */}
        <div className="mt-8">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Application Details
          </h3>
          <div className="hover-highlight bg-white shadow-lg rounded-lg p-6   transition-shadow duration-300">
            <p className="text-gray-600">
              <span className="font-semibold">Position:</span>{' '}
              {title || 'Job Title'}
            </p>
            <p className="text-gray-600 mt-2">
              <span className="font-semibold">Applied Date:</span>{' '}
              {formatDate(appData?.application?.applicationDate.slice(0, 10))}
            </p>
            <div className="mt-4 flex gap-6">
              <a href="#" className="icon-wrapper text-blue-500 hover-color">
                <svg
                  fill="currentColor"
                  height="800px"
                  width="800px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 490.4 490.4"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796
		s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z
		 M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z"
                    />
                  </g>
                </svg>
                <span onClick={() => setIsResumeModalOpen(true)}>
                  View Resume
                </span>
              </a>
              <a href="#" className="icon-wrapper text-blue-500 hover-color">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M13 2H6.5A1.5 1.5 0 0 0 5 3.5v17A1.5 1.5 0 0 0 6.5 22h11a1.5 1.5 0 0 0 1.5-1.5V8L13 2zm1 7V3.5L18.5 9H14z" />
                  <path d="M10 12h4v1h-4zm0 2h4v1h-4zm0 2h4v1h-4z" />
                </svg>
                <a href={resumeLink} download>
                  Download Resume
                </a>
              </a>
            </div>
          </div>
        </div>

        {status === 'REJECTED' ? (
          <></>
        ) : (
          <div className="mt-12">
            <h3 className="text-xl font-semibold text-gray-800 mb-6">
              Test Processes to Complete
            </h3>
            <TestProcesses
              applicationData={appData?.application}
              isHired={isHired}
            />{' '}
          </div>
        )}

        {/* <!-- Application Timeline --> */}
        <div className="mt-12">
          <h3 className="text-xl font-semibold text-gray-800 mb-6">
            Application Timeline
          </h3>
          {statusHistory && (
            <ApplicationStatusProgress statusHistory={statusHistory} />
          )}
        </div>

        {/* <!-- Feedback Section --> */}
        {!!reasonsToShowToCandidate && !!reasonsToShowToCandidate.length && (
          <div className="mt-12">
            <h3 className="text-xl font-semibold text-gray-800 mb-6">
              Feedback and Reasons
            </h3>
            <div className=" hover-highlight bg-gray-50 p-6 rounded-lg shadow-inner   transition-all duration-300">
              <ul className="list-disc pl-6 space-y-3 text-gray-600">
                {reasonsToShowToCandidate.map((reason, ind) => (
                  <li className="" key={ind}>
                    {reason}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* <!-- Job Details Section --> */}
        <div className="mt-12">
          <h3 className=" text-xl font-semibold text-gray-800 mb-6">
            Job Details
          </h3>
          <div className="hover-highlight bg-white shadow-lg rounded-lg p-8   transition-shadow duration-300">
            <h4
              className="text-3xl font-bold text-blue-600 hover:underline cursor-pointer"
              onClick={() => {
                navigate(`/${orgName}/job/${jobId}`);
              }}
            >
              {title || 'Job Title'}
            </h4>
            {/* <p className="text-gray-600 mt-3">
              Health Tech | Full-Time | Remote
            </p> */}
            <div className="mt-4 flex items-center gap-2.5 flex-wrap">
              {jobSpecificDetails.map((field, i) => {
                if (i < jobSpecificDetails.length - 1 && !!job) {
                  if (field.name === 'experience') {
                    const minExperience = job['minimumExperience'];
                    const maxExperience = job['maximumExperience'];

                    // Check if minExperience exists (0 or greater) and maxExperience exists (truthy value)
                    if (
                      (minExperience || minExperience === 0) &&
                      maxExperience
                    ) {
                      return (
                        <span className="bg-gray-100 px-3 py-1 w-fit rounded text-sm text-gray-700 job-tag">
                          {field.valueRenderingFunction(
                            minExperience,
                            maxExperience
                          )}
                        </span>
                      );
                    }
                    // If only minExperience exists, render it alone
                    else if (minExperience || minExperience === 0) {
                      return (
                        <span className="bg-gray-100 px-3 py-1 w-fit rounded text-sm text-gray-700 job-tag">
                          {field.valueRenderingFunction(minExperience)}
                        </span>
                      );
                    }
                  }
                  return (
                    <span className="bg-gray-100 px-2 py-1 w-fit rounded text-xs job-tag">
                      {field.valueRenderingFunction(
                        job[field.name],
                        field.name === 'jobType'
                          ? job['workHours']
                          : field.name === 'maximumSalary'
                            ? job['minimumSalary']
                            : field.name === 'minimumExperience'
                              ? jobData['maximumExperience']
                              : ''
                      )}
                    </span>
                  );
                }
              })}
            </div>
            <p className="text-gray-600 mt-6 leading-relaxed">
              {jobDescriptionSummary}
            </p>
            <a
              href="#"
              className="text-blue-500 mt-8 block hover-color"
              onClick={() => {
                navigate(`/${orgName}/job/${jobId}`);
              }}
            >
              View Full Job Details
            </a>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}

      {isResumeModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg w-[92vw] sm:w-[80vw] h-[90vh] flex flex-col">
            <div className="flex justify-between items-center mb-4 border-b pb-2">
              <h2 className="text-2xl font-bold text-gray-900">Resume</h2>
              <button
                onClick={() => setIsResumeModalOpen(false)}
                className="button text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center z-50"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex-grow overflow-auto">
              <Document
                file={resumeLink}
                onLoadSuccess={onDocumentLoadSuccess}
                className="w-full md:flex md:flex-col md:items-center justify-center"
              >
                <Page
                  pageNumber={pageNumber}
                  className="border border-gray-300 shadow-md"
                />
              </Document>
            </div>
            <div
              className={`flex flex-col md:flex-row gap-4 ${
                numPages > 1 ? 'justify-center' : 'justify-end'
              } items-center mt-4 border-t pt-4 relative`}
            >
              {numPages > 1 && (
                <div className="flex justify-center items-center gap-4">
                  <button
                    disabled={pageNumber <= 1}
                    style={{ cursor: pageNumber <= 1 && 'not-allowed' }}
                    onClick={previousPage}
                    className={`px-4 py-2 border rounded ${
                      pageNumber <= 1
                        ? 'text-gray-500 cursor-not-allowed'
                        : 'hover:bg-gray-200'
                    }`}
                  >
                    Previous
                  </button>

                  <span className="mx-2 px-4 py-2 border rounded">
                    Page {pageNumber} of {numPages}
                  </span>

                  <button
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                    style={{ cursor: pageNumber >= numPages && 'not-allowed' }}
                    className={`px-4 py-2 border rounded ${
                      pageNumber >= numPages
                        ? 'text-gray-500 cursor-not-allowed'
                        : 'hover:bg-gray-200'
                    }`}
                  >
                    Next
                  </button>
                </div>
              )}
              <a
                href={resumeLink}
                download
                className={`bg-green-600 text-white px-4 py-2 rounded ${
                  numPages > 1 ? 'md:absolute' : 'relative'
                } md:right-0 hover:bg-green-700 transition-all w-full md:w-auto text-center`}
              >
                Download
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppliedJobDetail;
