import api from '../../utils/api';

const resetPassword = (email, otp, password, recaptchaValue) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post('/v1/iam/reset-password', {
        email,
        otp,
        newPassword: password,
        gRecaptchaResponse: recaptchaValue
      });

      const data = response.data;

      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(new Error(data.message || 'An error occurred'));
      }
    } catch (error) {
      console.error('Error while resetting the password:', error);
      reject(new Error('An error occurred, please try again.'));
    }
  });
};

export default resetPassword;
