import React, { useEffect, useState } from 'react';
import Rating from '../Rating';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaStar } from 'react-icons/fa';
import { useHelperBot } from '../HelperBot';
import api from '../../utils/api';

const Ratings = ({
  appId,
  jobId,
  answers,
  questions,
  handleBackButtonClick
}) => {
  const [ratings, setRatings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reducedOpacity, setReducedOpacity] = useState(true);
  const navigate = useNavigate();
  const { orgName } = useParams();

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  const { helperMessage } = useHelperBot();
  useEffect(() => {
    helperMessage(
      'How do you feel about your skills? Rate yourself honestly—it’s just between us, and it’ll help a lot!'
    );
  }, []);

  const fetchRatings = async () => {
    setLoading(true);
    try {
      const url = `/v1/organization/${orgId}/job/${jobId}`;
      const { data } = await api.get(url);

      if (
        data &&
        data.data &&
        data.data.skills &&
        Array.isArray(data.data.skills)
      ) {
        setRatings(
          data.data.skills.map((skill) => ({ skill: skill, rating: '' }))
        );
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      console.error('Error fetching Questions', error);
      toast.error(error);
      navigate(`/${orgName}/404`);
    } finally {
      setLoading(false);
    }
  };

  const handleRatingChange = (index, rating) => {
    const newRatings = [...ratings];
    newRatings[index].rating = rating;
    setRatings(newRatings);
  };

  useEffect(() => {
    if (!!ratings && ratings.length > 0) {
      const unrated = ratings.find((r) => r.rating === '');
      if (!unrated) {
        setReducedOpacity(false);
      }
    }
  }, [ratings]);

  const handleSubmit = async () => {
    setLoading(true);
    const unratedSkills = ratings.filter((rating) => !rating.rating);

    if (unratedSkills.length > 0) {
      setLoading(false);
      toast.error('Please provide a rating for all skills before submitting.');
      return;
    }

    const payload = {
      answers: answers.map((answer, index) => ({
        question: questions[index],
        answer
      })),
      skillRatings: ratings.map((r) => ({ skill: r.skill, rating: r.rating }))
    };

    try {
      const url = `/v1/organization/${orgId}/job/${jobId}/application/${appId}/analyze-candidate`;
      await api.post(url, payload);

      toast.success('Form submitted successfully');
      setLoading(false);
      navigate(
        `/${orgName}/analyze-candidate/questions?jobId=${jobId}&appId=${appId}`
      );

      let arr = [
        'I’m checking your skills against the job. Hang tight, we’re almost through!',
        'Just making sure everything lines up. You’re doing great!',
        'Okay, almost there! We’ll be moving on in just a moment.'
      ];
      helperMessage(arr[Math.floor(Math.random() * arr.length)]);
    } catch (error) {
      console.error('Error submitting data', error);
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRatings();
  }, [jobId]);
  return (
    <>
      {/* <div className="bg-[url('images/headerbg.png')] bg-no-repeat bg-center bg-cover h-[45vh] relative bg-primary-600 "></div> */}
      <div className="container mx-auto px-5 md:px-0 -mt-[140px] relative z-[1] mb-20">
        <div className="flex grid-cols-12 lg:gap-8 justify-between w-full">
          <div className="col-span-12 lg:w-10/12 xl:w-full xl:col-span-8">
            <div className="p-8 rounded bg-white shadow-lg ">
              <div className="flex flex-col items-start mt-6 pb-6 border-b">
                <h6 className="text-3xl font-medium text-base-content">
                  Skill Proficiency Assessment
                </h6>
                <p className="mt-1 text-base font-medium text-base-content">
                  Demonstrate Your Proficiency: From Beginner to Expert
                </p>
              </div>

              <div className="block xl:hidden">
                <div className="p-6 w-[300px] rounded bg-white shadow-lg">
                  <div className="flex justify-between items-center py-2">
                    <span>Beginner</span>{' '}
                    <span className="text-[#fcd53f]">
                      <FaStar />
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <span>Basic</span>{' '}
                    <span className="flex text-[#fcd53f]">
                      <FaStar /> <FaStar />{' '}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <span>Intermediate</span>{' '}
                    <span className="flex text-[#fcd53f]">
                      <FaStar /> <FaStar />
                      <FaStar />
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <span>Advanced</span>{' '}
                    <span className="flex text-[#fcd53f]">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <span> Expert</span>{' '}
                    <span className="flex text-[#fcd53f]">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-8 pb-8 border-b border-base-content/10">
                {ratings.map((rating, i) => (
                  <Rating
                    key={i}
                    skill={rating.skill}
                    index={i + 1}
                    rating={rating.rating}
                    onRatingChange={(rating) => handleRatingChange(i, rating)}
                  />
                ))}
              </div>
              <div className="mt-8 flex flex-row md:flex-row items-center gap-5 md:gap-0 justify-center md:justify-between">
                <button
                  type="button"
                  onClick={handleBackButtonClick}
                  className="bg-primary-600 text-white py-2 px-4 rounded "
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={loading}
                  className={`bg-primary-600 text-white py-2 px-4  rounded ${
                    reducedOpacity ? 'bg-gray-600' : 'bg-primary-700'
                  }`}
                >
                  {loading ? (
                    <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                  ) : (
                    'Apply'
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="hidden xl:block xl:col-span-4">
            <div className="p-6 w-[300px] rounded bg-white shadow-lg">
              <div className="flex justify-between items-center py-2">
                <span>Beginner</span>
                <span className="text-[#fcd53f]">
                  <FaStar />
                </span>
              </div>
              <div className="flex justify-between items-center py-2">
                <span>Basic</span>{' '}
                <span className="flex text-[#fcd53f]">
                  <FaStar /> <FaStar />{' '}
                </span>
              </div>
              <div className="flex justify-between items-center py-2">
                <span>Intermediate</span>
                <span className="flex text-[#fcd53f]">
                  <FaStar /> <FaStar />
                  <FaStar />
                </span>
              </div>
              <div className="flex justify-between items-center py-2">
                <span>Advanced</span>{' '}
                <span className="flex text-[#fcd53f]">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </span>
              </div>
              <div className="flex justify-between items-center py-2">
                <span> Expert</span>{' '}
                <span className="flex text-[#fcd53f]">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ratings;
