import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatStringForFrontend } from '../../../utils/CommonFunctions';
import api from '../../../utils/api';

const TestProcesses = ({ applicationData, isHired }) => {
  const navigate = useNavigate();
  const { orgName } = useParams();
  const { jobId, appId } = useParams();
  const [testProcess, setTestProcess] = useState([]);

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  useEffect(() => {
    fetchCandidateTestProcess();
    console.log('isHired in TestProcesses', isHired);
  }, []);

  const fetchCandidateTestProcess = async () => {
    try {
      const response = await api.get(
        `/v1/organization/${orgId}/job/${jobId}/application/${appId}/candidate/test-process`
      );

      const data = response;

      if (response.statusText === 'OK') {
        // Handle success, e.g., update state or UI
        setTestProcess(data.data.data);
      } else {
        toast.error(data.message || 'An error occurred');
        throw new Error(data.message || 'API issue');
      }
    } catch (error) {
      toast.error(error.message);
      console.error('Fetch error: ', error.message);
      // navigate(`/${orgName}/404`);
    }
  };

  const startTest = async (testProcessId) => {
    try {
      const response = await api.post(
        `/v1/organization/${orgId}/job/${jobId}/application/${appId}/candidate/test-process/${testProcessId}/start`
      );

      const data = response.data;

      if (data?.details?.personalityTestData?._id) {
        navigate(
          `/${orgName}/personality-tests/${data.details.personalityTestData._id}/?jobId=${jobId}&appId=${appId}&testProcessId=${testProcessId}`
        );
      }
    } catch (error) {
      console.error('Error fetching questions', error);
      toast.error(error.message);
    }
  };

  const renderTestStatus = (status) => {
    if (isHired) {
      if (status === 'COMPLETED') {
        return (
          <span className="bg-green-500 text-white py-1 px-3 rounded-full text-xs">
            Completed
          </span>
        );
      } else {
        return (
          <span className="bg-gray-400 text-white py-1 px-3 rounded-full text-xs">
            Not Required
          </span>
        );
      }
    }
    switch (status) {
      case 'NOT_STARTED':
        return (
          <span className="bg-[#ffc009] text-black py-1 px-3 rounded-full text-xs">
            Not&nbsp;Started
          </span>
        );
      case 'PENDING':
        return (
          <span className="bg-[#ffc009] text-black py-1 px-3 rounded-full text-xs">
            Not&nbsp;Started
          </span>
        );
      case 'IN_PROGRESS':
        return (
          <span className="bg-[#ffc009] text-black py-1 px-3 rounded-full text-xs">
            In&nbsp;Progress
          </span>
        );
      case 'FAILED':
        return (
          <span className="bg-red-500 text-white py-1 px-3 rounded-full text-xs">
            Failed
          </span>
        );
      case 'COMPLETED':
        return (
          <span className="bg-green-500 text-white py-1 px-3 rounded-full text-xs">
            Completed
          </span>
        );
      default:
        return <p>Unknown status</p>;
    }
  };

  const findObjectWithType = (type) => {
    // Use the find method to search for the object with testProcess type "MCQ"
    return testProcess.find((item) => item.testProcess.type === type);
  };

  const sortOrder = ['AI_ANALYSIS', 'SKILL_BASED_MCQ_TEST', 'PERSONALITY_TEST'];

  return (
    <div className="card mt-5">
      {/* <div className="bg-primary-600 text-white flex items-center p-4 rounded-t">
        <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 16 16">
          <path
            fillRule="evenodd"
            d="M1.5 2.5A.5.5 0 0 1 2 2h7a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5zm0 4A.5.5 0 0 1 2 6h7a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5zm0 4A.5.5 0 0 1 2 10h7a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5zm10.354-3.146a.5.5 0 1 0-.708-.708L10 8.293l-1.146-1.147a.5.5 0 0 0-.708.708L9.293 9l-1.147 1.146a.5.5 0 0 0 .708.708L10 9.707l1.146 1.147a.5.5 0 0 0 .708-.708L10.707 9l1.147-1.146z"
          />
        </svg>
        <h3 className="text-lg font-semibold m-0">
          Test Processes to Complete
        </h3>
      </div> */}
      <div className="p-4">
        <p className="text-gray-500 mb-4">
          Please complete the following assessments to proceed with your
          application.
        </p>

        {testProcess.length ? (
          <ul className="list-none">
            {testProcess.map((process, i) => {
              return (
                <li className="flex justify-between items-center bg-gray-100 p-3 rounded mb-2 relative hover-highlight max-sm:flex-col max-sm:items-center max-sm:gap-2">
                  <div className="max-sm:flex max-sm:flex-col max-sm:items-center max-sm:gap-2">
                    <h6 className="text-sm font-medium max-sm:flex max-sm:flex-col-reverse max-sm:items-center max-sm:gap-2">
                      {formatStringForFrontend(process.testProcess.type)}{' '}
                      {renderTestStatus(process.status)}
                    </h6>
                    {isHired ? (
                      <small className="text-gray-500 max-sm:text-center">
                        Already Hired. No need to GiveTest.
                      </small>
                    ) : ['FAILED', 'COMPLETED'].includes(process.status) ? (
                      <small className="text-gray-500 max-sm:text-center">
                        This step has been completed successfully.
                      </small>
                    ) : (
                      <small className="text-gray-500 max-sm:text-center">
                        Not started. Please begin the test when ready.
                      </small>
                    )}
                  </div>
                  {!isHired &&
                  ['NOT_STARTED', 'PENDING', 'IN_PROGRESS'].includes(
                    process.status
                  ) ? (
                    <>
                      {(process.testProcess.type === 'AI_ANALYSIS' ||
                        findObjectWithType(testProcess[i - 1].testProcess.type)
                          ?.status === 'COMPLETED') &&
                        !(
                          process.status === 'IN_PROGRESS' &&
                          process.testProcess.type === 'SKILL_BASED_MCQ_TEST'
                        ) && ( // New condition to not render for 'IN_PROGRESS' & 'SKILL_BASED_MCQ_TEST'
                          <button
                            className="bg-primary-500 text-white text-lg w-32 py-1 px-2 max-sm:my-2 rounded shadow-lg"
                            onClick={() => {
                              if (process.testProcess.type === 'AI_ANALYSIS') {
                                navigate(
                                  `/${orgName}/questions/${jobId}/${appId}`
                                );
                              }
                              if (
                                process.testProcess.type ===
                                'SKILL_BASED_MCQ_TEST'
                              ) {
                                navigate(
                                  `/${orgName}/preassesment/${jobId}/${appId}/${process._id}`
                                );
                              }
                              if (
                                process.testProcess.type === 'APTITUDE_MCQ_TEST'
                              ) {
                                navigate(
                                  `/${orgName}/aptitude/${jobId}/${appId}/${process._id}`
                                );
                              }
                              if (
                                process.testProcess.type === 'PERSONALITY_TEST'
                              ) {
                                startTest(process._id);
                              }
                            }}
                          >
                            Start Test
                          </button>
                        )}
                    </>
                  ) : (
                    <></>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <ul class="list-none animate-pulse">
            {[0, 1, 2].map(() => {
              return (
                <li class="flex justify-between items-center bg-gray-100 p-3 rounded mb-2 relative h-[66px]">
                  <div className="w-1/2">
                    <div class="bg-gray-200 rounded-full h-4 w-1/2"></div>
                    <div class="bg-gray-200 rounded-full h-[15px] w-full"></div>
                  </div>
                  <div class="absolute right-5 top-6 flex flex-row items-center gap-2">
                    {/* <span class=" text-black py-1 px-3 rounded-full bg-gray-200 text-xs h-6 w-24"></span> */}
                    <button
                      class=" text-white text-xs py-1 px-2 bg-gray-200 rounded h-6 w-20 "
                      aria-label="Start MCQ Test"
                    ></button>
                  </div>
                </li>
              );
            })}
          </ul>
        )}

        {/* Additional Helper Text */}
        {findObjectWithType('SKILL_BASED_MCQ_TEST') &&
        findObjectWithType('AI_ANALYSIS')?.status === 'COMPLETED' &&
        [
          'PENDING',
          'IN_PROGRESS'
          // "FAILED",
          // "COMPLETED",
        ].includes(findObjectWithType('SKILL_BASED_MCQ_TEST')?.status) ? (
          <div
            className="bg-primary-100 border-l-4 border-primary-500 text-primary-700 p-4 mt-4"
            role="alert"
          >
            <strong>Note:</strong> Once you start the Skill-Based MCQ
            Assessment, you will have a limited amount of time to complete it.
            Ensure you are prepared before beginning.
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TestProcesses;
