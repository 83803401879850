import React, { useRef, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Question from '../Question';
import Ratings from '../Ratings';
import { toast } from 'react-toastify';
import ExamRestrictions from '../Multichoice/Restrictions';
import GuidelinesModal from './GuidelinesModal';
import JobDetailDrawer from '../JobDetailDrawer';
import { useHelperBot } from '../HelperBot';
import api from '../../utils/api';

const Questions = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();
  const [isQuestionAnswered, setIsQuestionAnswered] = useState(false);
  const [isDisabled, SetIsDisabled] = useState(true);
  const [isPasted, setIsPasted] = useState(false);
  const [isGuideLinesModalVisible, setIsGuideLinesModalVisible] =
    useState(false);
  const { jobId, appId } = useParams();
  const firstTextareaRef = useRef(null);
  const [nextClicked, setNextClicked] = useState(false);
  const { orgName } = useParams();
  const minChars = 40;

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  const { helperMessage } = useHelperBot();
  useEffect(() => {
    helperMessage(
      'Let’s knock out these quick questions together. The more you share, the better we can match you up. Ready? Let’s do this!'
    );
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsGuideLinesModalVisible(false);
      }
    };

    if (isGuideLinesModalVisible) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isGuideLinesModalVisible]);

  useEffect(() => {
    let isReloaded = localStorage.getItem('isReloaded');

    if (isReloaded == 'true') {
      setIsGuideLinesModalVisible(false);
    } else {
      setIsGuideLinesModalVisible(true);
    }
  }, []);

  const handleNext = () => {
    const allAnswersDetailed = answers.every(
      (answer) => answer.length >= minChars
    );
    if (!allAnswersDetailed && !nextClicked) {
      setNextClicked(true);
      setShowWarning(true);
      return;
    }

    const allAnswered = answers.every((answer) => answer.trim() !== '');
    if (allAnswered) {
      setIsQuestionAnswered(true);
      SetIsDisabled(false);
      toast.success('All questions answered successfully.');
    }
  };

  const handleAnswerChange = (index, answer) => {
    const newAnswers = [...answers];
    newAnswers[index] = answer;
    setAnswers(newAnswers);
    SetIsDisabled(!newAnswers.every((ans) => ans.trim() !== ''));
  };

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const url = `/v1/organization/${orgId}/job/${jobId}/application/${appId}`;
      const response = await api.get(url);
      const data = response.data;

      let callApiAgaim = false;

      if (data && data.data && data.data.application) {
        if (data.data.application.status === 'PROFILE_REVIEW_COMPLETED') {
          if (
            data.data.application.candidateAnalysis &&
            Array.isArray(data.data.application.candidateAnalysis.questions)
          ) {
            setLoading(false);
            setQuestions(data.data.application.candidateAnalysis.questions);
            setAnswers(
              new Array(
                data.data.application.candidateAnalysis.questions.length
              ).fill('')
            );
          } else {
            callApiAgaim = true;
          }
        } else {
          throw new Error('User not allowed to give this assessment');
        }
      } else {
        callApiAgaim = true;
      }

      if (callApiAgaim) {
        setTimeout(() => {
          fetchQuestions();
        }, 5000);
      }
    } catch (error) {
      console.error('Error fetching Questions', error);
      toast.error(error.message);
      navigate(`/${orgName}/404`);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchQuestions();
  }, [jobId, appId]);

  useEffect(() => {
    const navBar = document.getElementById('nav-bar');
    if (navBar) {
      if (loading) {
        navBar.classList.add('bg-primary-600', 'pb-5');
        navBar.classList.remove('bg-transparent');
      } else {
        navBar.classList.add('bg-transparent');
        navBar.classList.remove('bg-primary-600', 'pb-5');
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && questions.length > 0 && firstTextareaRef.current) {
      firstTextareaRef.current.focus();
    }
  }, [loading, questions]);

  return (
    <>
      {/* Show warning if answers have less than <minChars> characters*/}
      {showWarning && (
        <div className=" bg-[#00000057] z-30 fixed top-0 w-[100vw] h-[100vh] flex justify-center items-center">
          <div className="bg-white rounded-xl p-5 shadow-lg w-[80%] max-w-[500px] flex flex-col">
            <h1 className=" font-bold">Warning</h1>
            <hr className="my-4" />
            <p>
              Your responses are not detailed enough. Insufficient answers may
              result in application rejection.
            </p>
            <button
              className="w-fit self-end bg-primary-600 text-white px-3 py-2 rounded-md mt-5"
              onClick={() => {
                setShowWarning(false);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      )}
      <div className="bg-[url('images/headerbg.png')] flex flex-col items-center w-full h-[45vh] bg-primary-700 px-8 py-8 md:px-16" />
      {isGuideLinesModalVisible ? (
        <GuidelinesModal
          closeModal={() => {
            setIsGuideLinesModalVisible(false);
          }}
        />
      ) : (
        <>
          <ExamRestrictions isPasted={isPasted} setIsPasted={setIsPasted} />
          {!isQuestionAnswered ? (
            <>
              <div className="relative">
                <div className="absolute inset-0 z-[-1] w-full h-full bg-gray-100"></div>
                <div className="-mt-[140px] px-4 md:px-0 flex flex-col md:flex-row  gap-12 min-h-[60vh]">
                  <div className="container mx-auto questions flex flex-col bg-white w-full rounded p-4 md:p-8 mb-5 overflow-auto">
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-6 mt-6 pb-6 border-b-2 mb-2">
                      <div>
                        <h6 className="mt-1 text-3xl font-medium text-base-content">
                          Candidate Fit Assessment
                        </h6>
                        <p className="text-base font-medium text-base-content">
                          Answer the Following Questions to Assess Your Fit
                        </p>
                      </div>
                    </div>
                    {questions.map((question, i) => (
                      <Question
                        setIsPasted={setIsPasted}
                        key={i}
                        question={question}
                        index={i + 1}
                        answer={answers[i]}
                        onAnswerChange={(answer) =>
                          handleAnswerChange(i, answer)
                        }
                        textareaRef={i === 0 ? firstTextareaRef : null}
                      />
                    ))}
                    {!loading && (
                      <div className="flex justify-center mt-2">
                        <button
                          onClick={handleNext}
                          type="submit"
                          disabled={isDisabled}
                          className="h-10 px-5 text-white bg-primary-600 rounded font-semibold duration-150 focus:shadow-outline hover:bg-primary-700 transition disabled:bg-gray-600"
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Ratings
              jobId={jobId}
              appId={appId}
              answers={answers}
              questions={questions}
              handleBackButtonClick={() => setIsQuestionAnswered(false)}
            />
          )}
          {loading && (
            <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-100">
              <div className="flex flex-col justify-center items-center">
                <div className="w-16 h-16 border-4 mb-3 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                <div>
                  <label htmlFor="" className="font-bold">
                    Loading questions for you. Hold Tight!
                  </label>
                </div>
              </div>
            </div>
          )}
          <button
            className="fixed right-0 top-1/2 transform -translate-y-1/2 bg-primary-600 text-white py-3 px-2 rounded-l-md z-50"
            style={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}
            onClick={toggleDrawer}
          >
            Job Description
          </button>

          <JobDetailDrawer
            isOpen={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default Questions;
