import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExamRestrictions from '../../../Multichoice/Restrictions';
import GuidelinesModal from './GuidelinesModal';
import DiscQuestion from './Question';
import { useHelperBot } from '../../../HelperBot';
import api from '../../../../utils/api';

const DISCAssessment = () => {
  const { orgName, personalityTestId } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const jobId = queryParams.get('jobId');
  const appId = queryParams.get('appId');
  const testProcessId = queryParams.get('testProcessId');
  const isFinalTest = queryParams.get('isFinalTest');

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isPasted, setIsPasted] = useState(false);
  const [isGuideLinesModalVisible, setIsGuideLinesModalVisible] =
    useState(true);
  const [userAnswers, setUserAnswers] = useState({});
  const [visitedQuestions, setVisitedQuestions] = useState(new Set());
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { helperMessage } = useHelperBot();
  useEffect(() => {
    let arr = [
      'Nice work on the Skill-Based MCQ Assessment! Next up, we’ve got a few personality tests to help us understand you even better. Ready to dive in? Let’s go!',
      'This first test is all about how you see yourself. For each question, just pick the option that feels the most like you and the one that feels the least like you. Easy, right? Let’s get started!',
      'Just 24 questions here—take your time and trust your gut. It’s all about getting a true picture of you!',
      'You’re doing great! Remember, there are no right or wrong answers, just what feels right to you.'
    ];
    helperMessage(arr[Math.floor(Math.random() * arr.length)]);
  }, []);

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsGuideLinesModalVisible(false);
      }
    };

    if (isGuideLinesModalVisible) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isGuideLinesModalVisible]);

  useEffect(() => {
    let isReloaded = localStorage.getItem('isReloaded');

    if (isReloaded == 'true') {
      setIsGuideLinesModalVisible(false);
    } else {
      setIsGuideLinesModalVisible(true);
    }
  }, []);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/v1/organization/${orgId}/personality-test/${personalityTestId}/questionnaire`,
        { params: { type: 'DISC' } }
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        setQuestions(response.data.data);
      } else {
        throw new Error('Unexpected data structure in API response');
      }
    } catch (error) {
      console.error('Error fetching questions', error);
      toast.error(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerChange = (pageIndex, answerType, optionIndex) => {
    setUserAnswers((prevAnswers) => {
      const updatedAnswer = {
        ...prevAnswers[pageIndex],
        [answerType]: optionIndex
      };

      if (updatedAnswer.mostLike === updatedAnswer.leastLike) {
        delete updatedAnswer[
          answerType === 'mostLike' ? 'leastLike' : 'mostLike'
        ];
      }

      return { ...prevAnswers, [pageIndex]: updatedAnswer };
    });
  };

  const handleNext = () => {
    if (currentPage < questions.length - 1 && isCurrentQuestionValid()) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setVisitedQuestions((prev) => new Set(prev).add(nextPage));
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const isCurrentQuestionValid = () => {
    const answer = userAnswers[currentPage];
    return (
      answer && answer.mostLike !== undefined && answer.leastLike !== undefined
    );
  };

  const getQueryParams = () => {
    let queryStr = '?';
    if (!!jobId && !!appId && !!testProcessId) {
      queryStr += `jobId=${jobId}&appId=${appId}&testProcessId=${testProcessId}&`;
    }
    return queryStr;
  };

  const handleSubmit = async () => {
    if (isAssessmentComplete()) {
      try {
        setLoading(true);

        const formattedResponses = questions.map((question, index) => {
          const answer = userAnswers[index] || {};
          return {
            mostLikely: question.options[answer.mostLike].word || null,
            leastLikely: question.options[answer.leastLike].word || null,
            options: question.options.map((option) => option.word)
          };
        });

        let bodyData = {
          responses: formattedResponses
        };

        if (jobId) {
          bodyData['jobId'] = jobId;
        }
        if (testProcessId) {
          bodyData['testProcessId'] = testProcessId;
        }

        await api.post(
          `/v1/organization/${orgId}/personality-test/${personalityTestId}/disc`,
          bodyData
        );

        toast.success('Assessment submitted successfully');
      } catch (error) {
        console.error('Error submitting assessment', error);
        toast.error(error.message);
      } finally {
        const queryStr = getQueryParams();
        navigate(
          `/${orgName}/analyze-candidate/personality-test${queryStr}personalityTestId=${personalityTestId}&isFinalTest=${isFinalTest}`
        );
        setLoading(false);
      }
    } else {
      toast.error('Please answer all questions before submitting.');
    }
  };

  const isAssessmentComplete = () => {
    return questions.every((question, index) => {
      const answer = userAnswers[index];
      return (
        answer &&
        answer.mostLike !== undefined &&
        answer.leastLike !== undefined
      );
    });
  };

  const handleNextClick = () => {
    if (!isCurrentQuestionValid()) {
      toast.error(
        'Please answer both MOST LIKE and LEAST LIKE before proceeding.'
      );
    } else {
      handleNext();
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
        <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  if (questions.length === 0) {
    return <div>No questions available.</div>;
  }

  const currentQuestion = questions[currentPage];

  return (
    <>
      <div className="bg-[url('images/headerbg.png')] flex flex-col items-center w-full h-[45vh] bg-primary-700 px-8 py-8 md:px-16"></div>

      {isGuideLinesModalVisible ? (
        <GuidelinesModal
          closeModal={() => {
            setIsGuideLinesModalVisible(false);
          }}
        />
      ) : (
        <>
          <ExamRestrictions isPasted={isPasted} setIsPasted={setIsPasted} />

          <div className="container flex px-5 md:px-0 -mt-[140px] pl-[10%] pr-[10%] relative z-[0] mb-20 w-full mx-auto">
            <div className="bg-white p-8 rounded-lg shadow-lg w-[100%] mt-[4%] ">
              <h1 className="text-2xl font-bold mb-4 text-center">
                DISC Online Assessment
              </h1>
              <p className="text-lg mb-6 text-justify md:text-center">
                In each set of four words, select the one word that is{' '}
                <strong>MOST LIKE YOU</strong>. Then select the one word that is{' '}
                <strong>LEAST LIKE YOU</strong>.
              </p>
              <div className="text-xl font-bold text-center mb-4">
                Question {currentPage + 1} of {questions.length}
              </div>
              <form onSubmit={(e) => e.preventDefault()}>
                <DiscQuestion
                  question={currentQuestion}
                  onAnswerChange={handleAnswerChange}
                  userAnswers={userAnswers}
                  currentPage={currentPage}
                />

                <div className="flex justify-between mt-8">
                  <button
                    type="button"
                    onClick={handlePrevious}
                    disabled={currentPage === 0}
                    className={`${
                      currentPage === 0
                        ? 'bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-lg opacity-50 cursor-not-allowed'
                        : 'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-lg'
                    }`}
                  >
                    Previous
                  </button>
                  {currentPage < questions.length - 1 ? (
                    <button
                      type="button"
                      onClick={handleNextClick}
                      disabled={!isCurrentQuestionValid()}
                      className={`${
                        !isCurrentQuestionValid()
                          ? 'bg-primary-500 text-white font-bold py-2 px-4 rounded-lg opacity-50 cursor-not-allowed'
                          : 'bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded-lg'
                      }`}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleSubmit}
                      disabled={!isAssessmentComplete()}
                      className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded-lg disabled:opacity-50"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      {loading && (
        <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
          <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
        </div>
      )}
    </>
  );
};

export default DISCAssessment;
