import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getTextBasedOnTest } from './utils';
import { formatStringForFrontend } from '../../../utils/CommonFunctions';
import LoginModal from '../../LoginModal';
import api from '../../../utils/api';

const PersonalityAssesments = () => {
  const { personalityTestId } = useParams();
  const { orgName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const jobId = queryParams.get('jobId');
  const appId = queryParams.get('appId');
  const testProcessId = queryParams.get('testProcessId');
  const [tests, setTests] = useState({});
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
    fetchPersonalityTest();
  };

  const fetchPersonalityTest = async () => {
    try {
      const session = localStorage.getItem('session');
      const accessToken = session ? JSON.parse(session).accessToken : null;

      if (!accessToken) {
        setIsLoginModalOpen(true);
        return;
      }

      const response = await api.get(
        `/v1/organization/${orgId}/personality-test/${personalityTestId}`
      );
      const data = response.data;

      let testData = {};
      if (data.data.discTest) {
        testData['DISC'] = data.data.discTest;
      }
      if (data.data.motivatorsTest) {
        testData['Motivators'] = data.data.motivatorsTest;
      }
      if (data.data.hvpPlusTest) {
        testData['HVP Plus'] = data.data.hvpPlusTest;
      }
      setTests(testData);
    } catch (error) {
      console.error('Error fetching questions', error);
      toast.error(error);
      navigate(`/${orgName}/404`);
    }
  };

  useEffect(() => {
    fetchPersonalityTest();
  }, []);

  const allowedToStartTest = (test) => {
    if (test === 'DISC') {
      return tests['DISC'].status === 'NOT_STARTED';
    }
    if (test === 'Motivators') {
      return (
        (!tests['DISC'] ||
          (tests['DISC'] && tests['DISC'].status === 'COMPLETED')) &&
        tests['Motivators']?.status === 'NOT_STARTED'
      );
    }
    if (test === 'HVP Plus') {
      return (
        (!tests['DISC'] ||
          (tests['DISC'] && tests['DISC'].status === 'COMPLETED')) &&
        (!tests['Motivators'] ||
          (tests['Motivators'] &&
            tests['Motivators'].status === 'COMPLETED')) &&
        tests['HVP Plus'].status === 'NOT_STARTED'
      );
    }
  };

  const getQueryParams = () => {
    let queryStr = '?';
    if (!!jobId && !!appId && !!testProcessId) {
      queryStr += `jobId=${jobId}&appId=${appId}&testProcessId=${testProcessId}&`;
    }
    return queryStr;
  };

  const handleRouteToTest = (test, index) => {
    let isFinalTest = false;
    if (index + 1 === Object.keys(tests).length) {
      isFinalTest = true;
    }
    const queryStr = getQueryParams();
    if (test === 'DISC') {
      navigate(
        `/${orgName}/disc-assessment/${personalityTestId}${queryStr}isFinalTest=${isFinalTest}`
      );
    }
    if (test === 'Motivators') {
      navigate(
        `/${orgName}/motivators-assessment/${personalityTestId}${queryStr}isFinalTest=${isFinalTest}`
      );
    }
    if (test === 'HVP Plus') {
      navigate(
        `/${orgName}/hvp-plus-assessment/${personalityTestId}${queryStr}isFinalTest=${isFinalTest}`
      );
    }
  };

  return (
    <div className="font-sans leading-relaxed tracking-wide">
      <div className="bg-[url('images/headerbg.png')] bg-no-repeat bg-center bg-cover h-[304px] relative bg-primary-600 z-[1]"></div>
      <div className="container mt-[-144px] mb-[10vh] z-[1] mx-auto p-6 sm:p-10 relative bg-white shadow-lg rounded-lg  animate-fade-in">
        <div className="bg-gradient-to-br from-primary-500 to-gray-500 text-white text-center p-12 rounded-lg mb-4">
          <h1 className="text-5xl font-extrabold">
            Welcome to Your Personality Test
          </h1>
          <p className="text-xl mt-4">
            Gain insights into your personality, motivations, and values through
            our comprehensive assessments.
          </p>
        </div>

        <div className="">
          {/* Section Title */}
          <div className="row mb-10">
            <div className="col text-center">
              <h2 className="font-bold mb-4 text-3xl">Your Available Tests</h2>
              <p className="text-gray-500">
                Complete these tests to unlock valuable insights about your
                personality and fit within a team or organization.
              </p>
            </div>
          </div>
          {/* Test Cards */}
          <div className="gap-4 mb-10 flex flex-col md:flex-row">
            {Object.keys(tests).map((test, index) => {
              return (
                <div className="col-md-4 flex-1">
                  <div className="flex flex-col bg-gradient-to-br from-gray-100 to-gray-200 border border-gray-300 rounded-xl shadow-lg p-6 justify-between h-full transition-transform duration-300 hover:translate-y-1 hover:shadow-2xl">
                    <div className="flex items-center mb-4">
                      <div
                        className={`w-12 h-12 ${
                          test === 'DISC'
                            ? 'bg-blue-500'
                            : test === 'Motivators'
                              ? 'bg-yellow-500'
                              : 'bg-blue-300'
                        } text-white flex items-center justify-center rounded-full mr-3 text-2xl`}
                      >
                        <i className="bi bi-person-lines-fill"></i>
                      </div>
                      <h4 className="text-xl font-bold mb-0">{test} Test</h4>
                    </div>
                    {getTextBasedOnTest(test)}
                    <div className="flex justify-between items-center mt-6">
                      <span
                        className={`${
                          tests[test].status === 'COMPLETED'
                            ? 'text-green-500'
                            : 'text-red-500'
                        } text-xl font-bold flex items-center`}
                      >
                        <i className="bi bi-check-circle mr-1"></i>{' '}
                        {formatStringForFrontend(tests[test].status)}
                      </span>
                      {allowedToStartTest(test) && (
                        <button
                          className="bg-green-600 text-white text-xl px-5 py-2 rounded-full font-bold transition-colors duration-300 hover:bg-green-700"
                          onClick={() => handleRouteToTest(test, index)}
                        >
                          Start
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Additional Information Section */}
          <div className="row mt-10">
            <div className="col text-center">
              <h2 className="font-bold text-3xl mb-4">
                Why These Tests Matter
              </h2>
              <p className="text-lg text-gray-600">
                These assessments provide you with a deeper understanding of
                your personality, motivations, and values. Completing them will
                not only help you in your current role but also in future career
                decisions. By knowing yourself better, you can make informed
                choices that align with your professional and personal goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <LoginModal isOpen={isLoginModalOpen} onClose={handleCloseLoginModal} />
    </div>
  );
};

export default PersonalityAssesments;
