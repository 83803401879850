import axios from 'axios';

// Axios instance
const api = axios.create({
  baseURL: `${process.env.REACT_APP_DEV_API}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Refresh token logic
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const redirectToLogin = () => {
  const org = localStorage.getItem('org');
  const orgName = org ? JSON.parse(org).name : '';
  localStorage.clear();
  localStorage.setItem('openLoginModal', true);
  window.location.href = `/${orgName}`;
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      // Prevent duplicate refresh calls
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const session = JSON.parse(localStorage.getItem('session'));
        const refreshToken = session?.refreshToken;

        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        const response = await fetch(
          `${process.env.REACT_APP_DEV_API}/v1/iam/update-session`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken
            })
          }
        );

        const data = await response.json();

        if (data.status === 'success') {
          const newSession = data.data.session;
          const newAccessToken = newSession?.accessToken;
          localStorage.setItem('user', JSON.stringify(data.data.user));
          localStorage.setItem('session', JSON.stringify(data.data.session));

          api.defaults.headers.common['Authorization'] =
            `Bearer ${newAccessToken}`;
          processQueue(null, newAccessToken);

          return api(originalRequest);
        } else {
          throw new Error(data.message || 'Failed to refresh tokens');
        }
      } catch (error) {
        console.error('Error refreshing tokens:', error);
        processQueue(error, null);
        redirectToLogin();
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

// Attach token to requests
api.interceptors.request.use(
  (config) => {
    try {
      const session = JSON.parse(localStorage.getItem('session') || '{}');
      const token = session?.accessToken;
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
      }
      return Promise.reject(new Error('No authentication token available'));
    } catch (error) {
      console.error('Error parsing session:', error);
      return config;
    }
  },
  (error) => Promise.reject(error)
);

export default api;
